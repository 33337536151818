const enableDebug = false;

var anim = {
  initElements: function() {

        if (jQuery('.reveal').length > 0){    
          gsap.utils.toArray(".reveal").forEach(function(elem) {
            hide(elem); 
          });
        }

        if (jQuery('.split-text').length > 0){ 
          jQuery('.split-text').each(function() {
            jQuery(this).wrapInner( "<span></span>");
          });
        }
    
  },
  animateElements: function() {

        if (jQuery('.reveal').length > 0){    
          gsap.utils.toArray(".reveal").forEach(function(elem) {

            ScrollTrigger.create({
              trigger: elem, 
              onEnter: function() { animateFrom(elem) }, 
              onEnterBack: function() { animateFrom(elem, -1) },
              onLeave: function() { hide(elem) } // assure that the element is hidden when scrolled into view
            });
          });
        }

        if (jQuery('.split-text').length > 0){

          gsap.utils.toArray(".split-text span").forEach(function(elem) {
  
            elem._tl = gsap.timeline({paused:true}), 
            elem.childSplit = new SplitText(elem, {
              type: "lines",
              linesClass: "split-child"
            });
            const parentSplit = new SplitText(elem, {
              linesClass: "split-parent"
            });
  
            elem._tl.from(elem.childSplit.lines, {
              duration: 1.5,
              yPercent: 100,
              ease: "power4",
              stagger: 0.1
            });
  
            ScrollTrigger.create({
              trigger: elem, 
              onEnter: function() { 
                elem._tl.play();
               }, 
               onLeaveBack: function() { 
                elem._tl.pause(0);
               },
               onEnterBack: function() { 
                elem._tl.play();
              },
  
            });
          });
        }



  }
}

jQuery(function(){


  if (isIE()){
    jQuery("body").addClass("isIE");
  }


  
  if(!enableDebug){

    if (!readCookie("loaderShowed")) {
        
        if(!isIE() || (isIE() && ieVersion() > 9)){ 
          gsap.registerPlugin(ScrollTrigger);
          gsap.set(".site-loader svg", {css: {scale:0, opacity:0}});
    
        }
       
    }
    
  }


  if (jQuery(window).scrollTop() > 80){
    jQuery('.site-header').addClass('site-header--fixed');
    jQuery('.site-progress-container').addClass('scroll');
  }
      


});


jQuery(window).on("load", function () {

      // loader
      if(!enableDebug){

        if(!isIE() || (isIE() && ieVersion() != 9)){ 

          if (!readCookie("loaderShowed")) {

            createCookie("loaderShowed", "1", 2);
            anim.initElements();
            

            var animation = gsap.timeline({repeat:0,paused:true});
            animation.fromTo(".site-loader svg",0.5, {css: {scale:0.5,opacity:0}},{css:{scale:1,opacity:1,transformOrigin:"50% 50%"}, ease:Power1.easeInOut})
                      .fromTo(".site-loader", 1, {css: {opacity:1,width:'100%',marginLeft:'0%'}}, { css: {opacity:0,width:'0%',marginLeft:'50%'}, ease:Power1.easeInOut},"+=.8"); 


            animation.play().eventCallback("onComplete", function(){
              anim.animateElements();
              jQuery('.site-loader').removeClass('init');
              jQuery(".site-loader svg,.site-loader").removeAttr("style");
    
           });


          }else{

            jQuery('.site-loader').delay(500).fadeOut(500, function() {
              jQuery('.site-loader').removeClass('init');
              anim.initElements();
              anim.animateElements();
            });
            
          }


        }else{

          jQuery('.site-loader').delay(500).fadeOut(500, function() {
            jQuery('.site-loader').removeClass('init');
          });
        

        }
        

      }else{

        jQuery('.site-loader').delay(500).fadeOut(500, function() {
          jQuery('.site-loader').removeClass('init');
        });
      

      }



      //crop language switcher text
      if(jQuery('.wpml-ls > ul > li.wpml-ls-item').length > 0){
        jQuery('.wpml-ls > ul > li.wpml-ls-item').each(function( index ) {
          var linkLabel = jQuery( this ).find('a > span').text().substring(0, 2);
          jQuery( this ).find('a > span').text(linkLabel);
        });
        
      }
          
      //accordion
      /*if (jQuery('.site-content__accordion').length > 0){
        jQuery('.site-content__accordion .site-content__accordion__title').on("click tap", function(e) {
            e.preventDefault();
            var dataAttr = jQuery(this).attr('data-acc-title');

            //close all the others
            jQuery('.site-content__accordion .site-content__accordion__title').not(this).removeClass('active');
            jQuery('.site-content__accordion .site-content__accordion__content[data-acc-cont!="'+dataAttr+'"]').removeClass('open');

            jQuery(this).toggleClass('active');
            jQuery('.site-content__accordion .site-content__accordion__content[data-acc-cont="'+dataAttr+'"]').toggleClass('open');
            
        });
      }*/

      if (jQuery('.site-content__accordion').length > 0){

        jQuery('.site-content__accordion > p a.button').on("click tap", function(e) {
          e.preventDefault();
          jQuery(this).parent('p').parent('.site-content__accordion').addClass('open');
        });

        jQuery('.site-content__accordion .close').on("click tap", function(e) {
          e.preventDefault();
          jQuery(this).parent('.site-content__accordion').removeClass('open');
        });
      }


      if (jQuery('.site-content__accordion--complex').length > 0){

        jQuery('.site-content__accordion--complex .site-content__accordion__title a.open').on("click tap", function(e) {
          e.preventDefault();
          jQuery(this).css('opacity',0);
          jQuery(this).parents('.site-content__accordion__title').siblings('.site-content__accordion__content').addClass('active');
        });

        jQuery('.site-content__accordion--complex .site-content__accordion__content a.close').on("click tap", function(e) {
          e.preventDefault();
          jQuery(this).parents('.site-content__accordion--complex').find('a.open').css('opacity',1);
          jQuery(this).parents('.site-content__accordion__content').removeClass('active');

          var target = jQuery(this).parents('.site-content__accordion--complex');
            
            if (target.length) {
              jQuery('html,body').animate({
                scrollTop: target.offset().top
              }, 500);
            }
        });
      }


      //Replace all SVG images with inline SVG
      jQuery('img[src$=".svg"]').each(function(index, element) {
          var $img = jQuery(this),
          imgURL = $img.attr('src'),
          imgClass= ($img.attr('class') !='') ? $img.attr('class') : '';

          jQuery.get(imgURL, function(data) {
              // Get the SVG tag, ignore the rest
              var $svg = jQuery(data).find('svg');

              // Remove any invalid XML tags as per http://validator.w3.org
              $svg = $svg.removeAttr('xmlns:a');
              $svg = $svg.removeAttr('id');
              if(imgClass!='') $svg = $svg.addClass(imgClass);

              // Replace image with new SVG
              $img.replaceWith($svg);

          }, 'xml');
      });

      //cf7
      /*if (jQuery('.wpcf7-form input[type="checkbox"]').length > 0 ) {
        jQuery('.wpcf7-form input#acconsento').unwrap().unwrap();
        jQuery('.wpcf7-form input#acconsento').parent().find('span.wpcf7-list-item-label > label').unwrap(); 
      }*/





      jQuery('.site-search__form').on("submit", function(e) { 
          var s = jQuery( this ).find('input[name="s"]').val(jQuery.trim(jQuery( this ).find('input[name="s"]').val()));
          if (!s.val()) { 
              e.preventDefault(); 
              jQuery( this ).find('input[name="s"]').focus();
          }
      });

      
      jQuery('.site-header a.site-header__search').on("click tap", function(e) {
        e.preventDefault();
        jQuery('.site-search').addClass('opened');
        setTimeout(function(){ 
          jQuery('.site-search .site-search__wrapper,.site-search  .site-search__button').fadeIn(500);
        }, 600);

      });

      jQuery('.site-search .site-search__button').on("click tap", function(e) {
        e.preventDefault();
        jQuery('.site-search .site-search__wrapper,.site-search  .site-search__button').fadeOut(500);
        setTimeout(function(){ 
          jQuery('.site-search').removeClass('opened');
        }, 600);

        

      });

      //bind click on image with link in adiacent box
      if (jQuery('.bindClickImage').length > 0) {

          jQuery('.bindClickImage').each(function () {

              var currentDiv = jQuery(this),
              linkToGo = '',
              domainPath = window.location.origin;

              var nearestDiv = currentDiv.siblings('.zIndex2');
              linkToGo = nearestDiv.find('a').last().attr('href');

              if(linkToGo!='')
                currentDiv.find('img').bind('click tap',function(e){
                  e.preventDefault();

                  if(linkToGo.indexOf("http") == -1)
                    linkToGo  = domainPath+'/'+linkToGo;

                  window.location = linkToGo;
                });
          });
      }
      

      if (jQuery('img.aligncenter').length > 0) {
        jQuery('img.aligncenter').parent('p').css('textAlign','center');
      }

      //slick slider
      if (jQuery('.slick-slider').length > 0) {

        jQuery('.slick-slider').each(function (index) {

                var currentSlider = jQuery(this);
                var setDots = (currentSlider.hasClass('hasDots')) ? true : false;;
                var setArrows = (currentSlider.hasClass('hasArrows')) ? true : false;
                var setAutoplay = (currentSlider.hasClass('autoplay')) ? true : false;
                var fadeIn = (currentSlider.hasClass('fadeIn') || currentSlider.hasClass('homeSlider')) ? true : false;
                var aheight = (currentSlider.hasClass('adaptiveHeight')) ? true : false;


                  if(currentSlider.hasClass('multiple')){

                    if(currentSlider.hasClass('col4')){

                      currentSlider.slick({
                        autoplay: setAutoplay,
                        autoplaySpeed: 4000,
                        arrows: setArrows,
                        dots: setDots,
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        adaptiveHeight: aheight,
                        infinite: true,
                        fade: fadeIn,
                        cssEase: 'linear',
                        responsive: [{
                    
                          breakpoint: 1170,
                          settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                          }
                    
                        },{
                    
                          breakpoint: 970,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                          }
                    
                        },{
                    
                          breakpoint: 768,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                          }
                    
                        }, {
                    
                          breakpoint: 480,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                          }
                    
                        }]
                    });

                  }else if(currentSlider.hasClass('col3')){

                        currentSlider.slick({
                          autoplay: setAutoplay,
                          autoplaySpeed: 4000,
                          arrows: setArrows,
                          dots: setDots,
                          slidesToShow: 3,
                          slidesToScroll: 3,
                          adaptiveHeight: aheight,
                          infinite: true,
                          fade: fadeIn,
                          cssEase: 'linear',
                          responsive: [{
                      
                            breakpoint: 970,
                            settings: {
                              slidesToShow: 2,
                              slidesToScroll: 2,
                            }
                      
                          },{
                      
                            breakpoint: 768,
                            settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1,
                            }
                      
                          }, {
                      
                            breakpoint: 480,
                            settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1,
                            }
                      
                          }]
                      });

                    }else{
                        currentSlider.slick({
                          autoplay: setAutoplay,
                          autoplaySpeed: 4000,
                          arrows: setArrows,
                          dots: setDots,
                          slidesToShow: 2,
                          slidesToScroll: 2,
                          adaptiveHeight: aheight,
                          infinite: true,
                          fade: fadeIn,
                          cssEase: 'linear',
                          responsive: [{
                      
                            breakpoint: 970,
                            settings: {
                              slidesToShow: 2,
                              slidesToScroll: 2,
                            }
                      
                          },{
                      
                            breakpoint: 768,
                            settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1,
                            }
                      
                          }, {
                      
                            breakpoint: 480,
                            settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1,
                            }
                      
                          }]
                      });
                    }
                
    
                  }else{
                    currentSlider.slick({
                      autoplay: setAutoplay,
                      autoplaySpeed: 4000,
                      arrows: setArrows,
                      dots: setDots,
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      fade: fadeIn,
                      adaptiveHeight: aheight,
                      cssEase: 'linear',
                    });
                  }
               

        });
      }


      //text scrolling horizontally
      if (jQuery('.site-content__marquee').length > 0) {
          
        jQuery('.site-content__marquee').each(function (index) {
          jQuery(this).marquee({
            delayBeforeStart: 1000,
            direction: 'left',// 'left', 'right', 'up' or 'down'
            duration: 10000,
            gap: 20,// Space in pixels between the tickers
            pauseOnCycle: false,// On cycle pause the marquee
            pauseOnHover: false,
            startVisible: false// The marquee is visible initially positioned next to the border towards it will be moving          
          });
        });

      }

      

      jQuery( ".site-header:not('.mobile') .site-navigation__menu ul.menu li.menu-item-has-children ul.sub-menu" ).on({
          mouseenter: function() {
            jQuery( this ).parent("li.menu-item-has-children").addClass( "active" );
          },
          mouseleave: function() {
            jQuery( this ).parent("li.menu-item-has-children").removeClass( "active" );
          }
      })


      
      

      //hamburger menu animation
      jQuery('.site-header .site-navigation__menu__button').on("click tap", function(e) {
          e.preventDefault(); 
          jQuery(this).find('.btn').toggleClass('active');
          jQuery(this).find('.btn').toggleClass('not-active');
          jQuery('.site-header .site-navigation__menu').slideToggle('fast');
          jQuery('.site-header').toggleClass('openedMenu');
          jQuery('.site-top').toggleClass('openedMenu');
      });


      //mobile menu
      if(Modernizr.mq('(min-width: 1170px)'))
          jQuery('.site-header').removeClass('mobile');
        else
          jQuery('.site-header').addClass('mobile');
      

      var cachedWidth = jQuery(window).width();
      checkParallaxParams(cachedWidth);

      jQuery(window).on('orientationchange, resize',function() {

        var newWidth = jQuery(window).width();
  
        if(Modernizr.mq('(min-width: 1170px)'))
          jQuery('.site-header').removeClass('mobile');
        else
          jQuery('.site-header').addClass('mobile');
                             
          
          resetHeaderNavigation();
          checkParallaxParams(newWidth);

      });


      jQuery(window).scroll(function(){


        var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        var scrolled = (winScroll / height) * 100;
        document.getElementById("progressBar").style.width = scrolled + "%";


        if (jQuery(this).scrollTop() > 100)
          jQuery('.scrollToTop').fadeIn();
        else 
          jQuery('.scrollToTop').fadeOut();
        

        //fixed header
        if (jQuery(this).scrollTop() > 80) {
          jQuery('.site-header').addClass('site-header--fixed');
          jQuery('.site-progress-container').addClass('scroll');
          resetHeaderNavigation();
        } else {
          jQuery('.site-header').removeClass('site-header--fixed');
          jQuery('.site-progress-container').removeClass('scroll');
        }

      });

      jQuery(".scrollToTop").on("click tap", function(e) {
        e.preventDefault();
        jQuery("html, body").animate({ scrollTop: 0 }, 500);
        return false;
      });

      jQuery("a[href^='#']:not(.loadMore):not(.scrollToTop)").not('[href="#"]').on("click tap", function(e) {
          e.preventDefault();
          if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = jQuery(this.hash);
            target = target.length ? target : jQuery('[name=' + this.hash.slice(1) +']');
            if (target.length) {
              jQuery('html,body').animate({
                scrollTop: target.offset().top
              }, 500);
              return false;
            }
          }
      });
   
})


function is_touch_device() {
  return 'ontouchstart' in window        // works on most browsers
      || navigator.maxTouchPoints;       // works on IE10/11 and Surface
};


function animateFrom(elem, direction) {
  direction = direction | 1;
  
  var x = 0,
      y = direction * 100;
  if(elem.classList.contains("reveal_fromLeft")) {
    x = -100;
    y = 0;
  } else if(elem.classList.contains("reveal_fromRight")) {
    x = 100;
    y = 0;
  }
  gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
    duration: 2, 
    x: 0,
    y: 0, 
    autoAlpha: 1, 
    ease: "expo", 
    overwrite: "auto"
  });
}

function hide(elem) {
  gsap.set(elem, {autoAlpha: 0});
}


function isIE(){
  var ua = window.navigator.userAgent;
  
  var is_ie = false;
  
  if(ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1)
    is_ie = true;

  return is_ie;
}

function ieVersion(){
    var ua = window.navigator.userAgent;
    var version = 0;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        version = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    return version;
}


function checkParallaxParams(w){

  var parImgSlow = 30,
    parImgFast = 60,
    parText = 25;

  if(w < 768){
    parImgSlow = 10,
    parImgFast = 10,
    parText = 10;
  }



  jQuery('.parallax-img-slow').parallaxContent({
    "shift":parImgSlow,
    "duration": 1
  });

  
  jQuery('.parallax-img-fast').parallaxContent({
    "shift":parImgFast,
    "duration": 1
  });

  jQuery('.parallax-text').parallaxContent({
    "shift":parText,
    "duration": 2
  });
}

function resetHeaderNavigation(){
  jQuery('.site-header').removeClass('openedMenu');
  jQuery('.site-header .site-navigation__menu').removeAttr("style");
  jQuery('.site-header .site-navigation__menu__button .btn').removeClass('active').addClass('not-active');
  jQuery('.site-header .site-navigation__menu ul.menu li.menu-item-has-children').removeClass( "opened" );
}


function createCookie(name, value, days) {
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      var expires = "; expires=" + date.toGMTString();
  } else var expires = "";
  document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}




function convertTextAlign(s){
  var styles = s.prop('style'),value='';
  if(styles.textAlign!=''){

    if(styles.textAlign=='center')
      value='alignCenter';
    else if(styles.textAlign=='right')
      value='alignRight';
  }
    
  return value;
}